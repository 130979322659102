<template>
    <div class="container-fluid view add-invoices">
        <button type="button" class="btn btn-secondary icon-btn go-back-btn" @click="$router.push({name: 'Invoices'})">
            {{ $t('close') }}
            <svg xmlns="http://www.w3.org/2000/svg" width="14.765" height="15.227" viewBox="0 0 14.765 15.227">
                <g>
                    <path d="M24.8 21.15l-5.236-5.236 5.236-5.237a.538.538 0 0 0 0-.78l-1.583-1.578-5.626 5.626L11.945 8.3l-1.578 1.578a.538.538 0 0 0 0 .78l5.233 5.237-5.214 5.255a.538.538 0 0 0 0 .78l1.578 1.578 5.626-5.626 5.645 5.645 1.578-1.578a.578.578 0 0 0-.013-.799z" transform="translate(-12.2 -7.3) translate(2 -1)"/>
                </g>
            </svg>
        </button>
        <div class="content-view borderless without-sidebar">
            <div class="action-header">
                <h1>{{ $t('Add Invoice') }}</h1>
                <div class="actions">
                    <button type="button" class="btn btn-grey" @click="selectClient = true">
                        {{ $t('Select client') }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.285" height="18.285" viewBox="0 0 18.285 18.285">
                            <g>
                                <g>
                                    <path d="M8.148 2.5H4.87A2.37 2.37 0 0 0 2.5 4.87v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM18.415 2.5h-3.278a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339h-3.278a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM8.148 12.767H4.87a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37v-3.278a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339v-3.278a.339.339 0 0 1 .339-.337h3.278a.339.339 0 0 1 .339.339zm12.3-1.639a1.016 1.016 0 0 1-1.016 1.016h-1.979v1.978a1.016 1.016 0 1 1-2.032 0v-1.978h-1.977a1.016 1.016 0 1 1 0-2.032h1.977v-1.977a1.016 1.016 0 1 1 2.032 0v1.977h1.978a1.016 1.016 0 0 1 1.015 1.016z" transform="translate(-2.5 -2.5) translate(2.5 2.5) translate(-2.5 -2.5)"/>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <form @submit.prevent="createInvoice" class="invoice-form">
                <div class="form-group invoice-client">
                    <div>
                        <p>{{ $t('Client:') }}<b>{{ client.name || '' }}</b></p>
                        <FormErrors v-if="errors['company_id']" :errors="errors['company_id']"  />
                    </div>
                    <svg class="viewable" v-if="client.name" @click="client = {}" xmlns="http://www.w3.org/2000/svg" width="21.8" height="21.8" viewBox="0 0 26 26">
                        <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
                    </svg>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ $t('Invoice date') }}</label>
                                <datepicker
                                    :class="['form-control', {'has-error': errors['invoice_date']}]"
                                    :value="invoice.invoice_date"
                                    @update:modelValue="invoice.invoice_date = moment($event).format('yyyy-MM-DD')"
                                    :placeholder="invoice.invoice_date || $t('Invoice date')"
                                />

                                <FormErrors v-if="errors['invoice_date']" :errors="errors['invoice_date']"  />        
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ `${$t('Due Date')} (${$t('optional')})` }}</label>
                            <div class="clearable">
                                <datepicker
                                    :class="['form-control', {'has-error': errors['due_date']}]"
                                    :value="invoice.due_date"
                                    @update:modelValue="invoice.due_date = moment($event).format('yyyy-MM-DD')"
                                    :placeholder="invoice.due_date || `${$t('Due Date')} (${$t('optional')})`"
                                />
                                <svg class="clear viewable" v-if="invoice.due_date" @click="invoice.due_date = null" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 26 26">
                                    <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
                                </svg>
                            </div>

                            <FormErrors v-if="errors['due_date']" :errors="errors['due_date']"  />
                        </div>
                    </div>
                    <div class="detail-actions">
                        <div class="form-group">
                            <label>{{ $t('Billing details') }}</label>
                            <button :class="['btn btn-secondary', {'active': editBilling}]" @click.prevent="editBilling = !editBilling">{{ $t('Edit billing details') }}</button>
                        </div>
                        <div class="form-group">
                            <label>{{ $t('Company details') }}</label>
                            <button :class="['btn btn-secondary', {'active': editCompany}]" @click.prevent="editCompany = !editCompany">{{ $t('Edit company details') }}</button>
                        </div>
                    </div>
                </div>

                <template v-if="editBilling">
                    <div class="form-group">
                        <input v-model="invoice.billing_details.company" type="text" :class="['form-control', {'has-error': errors[`billing_details.company`]}]" :placeholder="$t('Company')" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.company']" :errors="errors['billing_details.company']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.address_1" type="text" :class="['form-control', {'has-error': errors[`billing_details.address_1`]}]" :placeholder="$t('Address 1')" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.address_1']" :errors="errors['billing_details.address_1']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.address_2" type="text" :class="['form-control', {'has-error': errors[`billing_details.address_2`]}]" :placeholder="`${$t('Address')} 2 (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.address_2']" :errors="errors['billing_details.address_2']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.city" type="text" :class="['form-control', {'has-error': errors[`billing_details.city`]}]" :placeholder="$t('City')" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.city']" :errors="errors['billing_details.city']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.state" type="text" :class="['form-control', {'has-error': errors[`billing_details.state`]}]" :placeholder="`${$t('State')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.state']" :errors="errors['billing_details.state']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.postcode" type="text" :class="['form-control', {'has-error': errors[`billing_details.postcode`]}]" :placeholder="$t('Postcode')" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.postcode']" :errors="errors['billing_details.postcode']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.country" type="text" :class="['form-control', {'has-error': errors[`billing_details.country`]}]" :placeholder="$t('Country')" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.country']" :errors="errors['billing_details.country']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.phone" type="text" :class="['form-control', {'has-error': errors[`billing_details.phone`]}]" :placeholder="`${$t('Phone number')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.phone']" :errors="errors['billing_details.phone']"  />
                    </div>
                    <div class="form-group">
                        <input v-model="invoice.billing_details.email" type="text" :class="['form-control', {'has-error': errors[`billing_details.email`]}]" :placeholder="$t('Email')" :disabled="disabled">

                        <FormErrors v-if="errors['billing_details.email']" :errors="errors['billing_details.email']"  />
                    </div>
                </template>

                <template v-if="editCompany">
                    <div class="form-group">
                        <input v-model="invoice.company_details.reg_nr" type="text" :class="['form-control', {'has-error': errors[`company_details.reg_nr`]}]" :placeholder="`${$t('Registration number')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['company_details.reg_nr']" :errors="errors['company_details.reg_nr']"  />
                    </div>

                    <div class="form-group">
                        <input v-model="invoice.company_details.tax_nr" type="text" :class="['form-control', {'has-error': errors[`company_details.tax_nr`]}]" :placeholder="`${$t('Tax number')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['company_details.tax_nr']" :errors="errors['company_details.tax_nr']"  />
                    </div>

                    <div class="form-group">
                        <input v-model="invoice.company_details.address" type="text" :class="['form-control', {'has-error': errors[`company_details.address`]}]" :placeholder="`${$t('Address')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['company_details.address']" :errors="errors['company_details.address']"  />
                    </div>

                    <div class="form-group">
                        <input v-model="invoice.company_details.bank" type="text" :class="['form-control', {'has-error': errors[`company_details.bank`]}]" :placeholder="`${$t('Bank')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['company_details.bank']" :errors="errors['company_details.bank']"  />
                    </div>

                    <div class="form-group">
                        <input v-model="invoice.company_details.swift" type="text" :class="['form-control', {'has-error': errors[`company_details.swift`]}]" :placeholder="`${$t('Swift')} (${$t('optional')})`" :disabled="disabled">

                        <FormErrors v-if="errors['company_details.swift']" :errors="errors['company_details.swift']"  />
                    </div>

                    <div class="form-group">
                        <input v-model="invoice.company_details.iban" type="text" :class="['form-control', {'has-error': errors[`company_details.iban`]}]" :placeholder="$t('iBank')" :disabled="disabled">

                        <FormErrors v-if="errors['company_details.iban']" :errors="errors['company_details.iban']"  />
                    </div>
                </template>

                <label class="add-details viewable">
                    {{ $t('Invoice details') }}
                    <span @click="invoice.invoice_details.push({})">
                        {{ $t('Add') }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.56" height="23.56" viewBox="0 0 23.56 23.56">
                            <g>
                                <g>
                                    <path d="M12.312.529a11.78 11.78 0 1 0 11.777 11.777A11.793 11.793 0 0 0 12.312.529zm0 5.888a.841.841 0 0 1 .838.845v4.205h4.212a.841.841 0 0 1 0 1.683H13.15v4.205a.841.841 0 1 1-1.683 0V13.15H7.262a.841.841 0 0 1 0-1.683h4.205V7.262a.841.841 0 0 1 .845-.845z" transform="translate(-0.529 -0.529) translate(0.529 0.529) translate(-0.529 -0.529)"/>
                                </g>
                            </g>
                        </svg>
                    </span>
                </label>

                <div class="form-group mb-0" v-for="(item, index) in invoice.invoice_details" :key="index">
                    <div class="invoice-details-row">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{ $t('Name') }}</label>

                                    <input v-model="invoice.invoice_details[index].name" type="text" :class="['form-control', {'has-error': errors[`invoice_details.${index}.name`]}]" :placeholder="$t('Name')">

                                    <FormErrors v-if="errors[`invoice_details.${index}.name`]" :errors="errors[`invoice_details.${index}.name`]"  />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>{{ $t('Price') }}</label>

                                    <input v-model="invoice.invoice_details[index].price" type="number" step="0.01" min="0.00" :class="['form-control', {'has-error': errors[`invoice_details.${index}.price`]}]" :placeholder="$t('Price')">

                                    <FormErrors v-if="errors[`invoice_details.${index}.price`]" :errors="errors[`invoice_details.${index}.price`]"  />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>{{ $t('Quantity') }}</label>
                                    
                                    <input v-model="invoice.invoice_details[index].quantity" type="number" step="1" min="1" :class="['form-control', {'has-error': errors[`invoice_details.${index}.quantity`]}]" :placeholder="$t('Quantity')">

                                    <FormErrors v-if="errors[`invoice_details.${index}.quantity`]" :errors="errors[`invoice_details.${index}.quantity`]"  />
                                </div>
                            </div>
                        </div>
                        <svg class="remove-field viewable" @click="invoice.invoice_details.splice(index,1)" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 26 26">
                            <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
                        </svg>
                    </div>
                </div>

                <FormErrors v-if="errors[`invoice_details`]" :errors="errors[`invoice_details`]" />
                
                <div class="invoice-overview">
                    <div class="row">
                        <div class="col-6">
                            <p>{{ $t('Total EUR') }}: <strong>{{ total }}</strong></p>
                            <p>{{ $t('Total VAT EUR') }}: <strong>{{ total_vat }}</strong></p>
                            <p>{{ $t('Total with VAT EUR') }}: <strong>{{ total_with_vat }}</strong></p>
                        </div>
                        <div class="col-6">
                            <div class="align-right">
                                <div class="form-group">
                                    <div class="radio-group">
                                        <input class="form-check-input" v-model="invoice.vat_rate" type="radio" :value="0" id="zero_vat">
                                        <label class="form-check-label" for="zero_vat">0 %</label>
                                    </div>
                                    <div class="radio-group">
                                        <input class="form-check-input" v-model="invoice.vat_rate" type="radio" :value="vat_rate" id="vat_rate">
                                        <label class="form-check-label" for="vat_rate">{{vat_rate}} %</label>
                                    </div>
                                </div>

                                <FormErrors v-if="errors['total']" :errors="errors['total']" />
                                <FormErrors v-if="errors['vat_rate']" :errors="errors['vat_rate']" />
                                <FormErrors v-if="errors['total_vat']" :errors="errors['total_vat']" />
                                <FormErrors v-if="errors['total_with_vat']" :errors="errors['total_with_vat']" />
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary create-invoice" :disabled="disabled">{{ $t('Save') }}</button>
            </form>
        </div>
        <transition name="fade">
            <modal-select-company-component v-if="selectClient" @closeModal="selectClient=false" @selected="clientSelected" />
        </transition>
    </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import ModalSelectCompanyComponent from "@/components/management/ModalSelectCompanyComponent";
import moment from "moment";
import FormErrors from "@/components/widgets/FormErrors";
import {companyService} from "@/services/companyService";
import {invoiceService} from "@/services/invoiceService";

export default {
    name: "AddInvoice",
    data() {
        return {
            invoice: {
                billing_details: {},
                company_details: {},
                invoice_details: [{}],
                invoice_date: moment(new Date()).format('yyyy-MM-DD'),
            },
            selectClient: false,
            moment: moment,
            client: {},
            editBilling: false,
            editCompany: false,
            vat_rate: null,
        }
    },
    computed: {
        total() {
            let total = 0
            this.invoice.invoice_details.forEach(item => {
                total += parseFloat(item.price || 0) * parseFloat(item.quantity || 0)
            })
            return total.toFixed(2)
        },
        total_vat() {
            let vat_rate = this.invoice.vat_rate || 0
            return (parseFloat(this.total) * (vat_rate / 100)).toFixed(2)
        },
        total_with_vat() {
            return (parseFloat(this.total) + parseFloat(this.total_vat)).toFixed(2)
        },
    },
    methods: {
        createInvoice() {
            this.disabled = true
            this.invoice.total = parseFloat(this.total)
            this.invoice.total_vat = parseFloat(this.total_vat)
            this.invoice.total_with_vat = parseFloat(this.total_with_vat)
            invoiceService.createInvoice(this.invoice)
                .then(response => this.$router.push({ name: 'ViewInvoice', params: { id: response.data.id }}))
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        },
        clientSelected(client) {
            this.disabled = true
            this.invoice.company_id = client.id
            this.client = client
            companyService.getCompanyDetails(client.id)
                .then(response => {
                    this.invoice.company_details = response.data.company_details
                    this.invoice.billing_details = response.data.billing_details
                    this.vat_rate = this.invoice.vat_rate = response.data.vat_rate
                })
                .finally(() => this.disabled = false)
        },
        // updateInvoiceDueDate() {
            // this.invoice.due_date = this.moment(this.invoice.invoice_date).add(this.due_days, 'days')
        // }
    },
    components: {
        Datepicker,
        ModalSelectCompanyComponent,
        FormErrors,
    }
}
</script>